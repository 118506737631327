$(document).ready(function() {

	// generic year for copyright
    var d = new Date();
    $('.date-now').html(d.getFullYear());
   
   

   $('.hero-nav-left').click(function(e) {
    	e.preventDefault();
    	$('.hero').slick('slickPrev');

    });
    $('.hero-nav-right').click(function(e) {
    	e.preventDefault();
    	$('.hero').slick('slickNext');
    });

    if($('html').hasClass('touch')) {
       // change gallery hovers to 2-step clicks

       $(document).on('click','.sector a', function(e) {

            if(!$(this).hasClass('active')) {
                e.preventDefault();
                $('.sector a').removeClass('active');
                $(this).addClass('active');
                console.log('no');
            }
       });
    }

    $(window).scroll(function(e) {
        //console.log($('body, html').scrollTop());
        var offset = $('body').scrollTop();
        var offsetIE = $('html').scrollTop();
        if ((offset <= 10) && (offsetIE <= 10)) {
            $('#header, #mobile-footer').removeClass('page-scrolled');
        }
        else {
            $('#header, #mobile-footer').addClass('page-scrolled');
        }
    });

    //$('a button').click(function(e){
    //    e.preventDefault();
    //    console.log('stop button');
    //});

    // menu collapse

    // variable to toggle first element override
    var showFirstSub = 1; //0 for no, 1 for yes

    // alternative data date option
    var dataDate = $('.menu-collapse').attr('data-date');

    if(!dataDate) { dataDate = ''; }

    $('.menu-collapse [data-action="collapse"]').each(function(index) {

        if ((showFirstSub) && (index === 0) && (dataDate.length < 1)) {
            $(this).addClass('active');
        }
        else if ((dataDate.length > 1) && (dataDate == $(this).attr('data-date'))) {
            //console.log('oi' + ', ' + dataDate + ', ' + $(this).attr('data-date'));
                $(this).addClass('active');
        }
        else {
            var subItem = $(this).attr('data-target');
            $('.' + subItem).hide();
        }



        //console.log($(this).attr('data-target') + ', ' + index);

    });

    $('.menu-collapse [data-action="collapse"]').click(function(e) {
        e.preventDefault();
        var subItem = $(this).attr('data-target');
        //$('.' + subItem).show();
        //console.log('clicked');

        $('.' + subItem).each(function() {
            if ($(this).is(':visible')) {
                $(this).hide();
                //$(this).slideUp();
            }
            else {
                $(this).show();
                //$(this).slideDown();
            }
        });

        //if('.' + subItem + ':visible')
    });

   $('.central table').wrap('<div class="table-responsive"></div>');

});

$(window).load(function() {

    $('.hero').slick({
        autoplay: true,      
        autoplaySpeed: 8000,
        dots: true,
        arrows: false,
        speed: 600,
        slide: '.hero-item'
    });

    $('.client-list').slick({
        autoplay: true,      
        autoplaySpeed: 2000,
        dots: false,
        arrows: false,
        speed: 600,
        slide: '.client-item',
        slidesToShow: 3,
        slidesToScroll: 1
    });

});
